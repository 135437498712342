import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import WalletFullRow from './WalletFullRow';
import { getFullBalance } from '../../services/ExchangeService';
import Toast from '../../components/Toast/Toast';
import NewOrderButton from '../../components/NewOrder/NewOrderButton';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import SelectFiat, {
    setDefaultFiat,
    getDefaultFiat,
} from '../../components/SelectFiat/SelectFiat';

function WalletFull() {
    const history = useHistory();

    const [balances, setBalances] = useState([]);
    const [total, setTotal] = useState('');
    const [fiat, setFiat] = useState(getDefaultFiat());
    const [isLoading, setIsLoading] = useState(false);
    const [isFuture, setIsFuture] = useState(false);

    const [notification, setNotification] = useState({ type: '', text: '' });

    useEffect(() => {
        if (!fiat) return;

        setIsLoading(true);
        getFullBalance(fiat, isFuture)
            .then(info => {
                setTotal(info.fiatEstimate);
                delete info.fiatEstimate;

                const balances = Object.entries(info)
                    .map(item => {
                        return {
                            symbol: item[0],
                            available: item[1].available,
                            onOrder: item[1].onOrder,
                            fiatEstimate: item[1].fiatEstimate,
                            avg: item[1].avg,
                        };
                    })
                    .sort((a, b) => {
                        if (a.symbol > b.symbol) return 1;
                        if (a.symbol < b.symbol) return -1;
                        return 0;
                    });

                setBalances(balances.filter(b => b.available));
                setIsLoading(false);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification(err.response ? err.response.data : err.message);
                setIsLoading(false);
            });
    }, [fiat, isFuture]);

    function onOrderSubmit(event) {
        history.go(0);
    }

    function onFiatChange(event) {
        setDefaultFiat(event.target.value);
        setFiat(event.target.value);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">Wallet</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        {localStorage.getItem('hasFutures') === 'true' ? (
                            <div className="me-2">
                                <button
                                    className="btn btn-secondary me-2"
                                    onClick={() => setIsFuture(!isFuture)}
                                >
                                    {isFuture ? 'Futures' : 'Spot'}
                                </button>
                            </div>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                        <div className="me-2">
                            <SelectFiat onChange={onFiatChange} fiat={fiat} />
                        </div>
                        <NewOrderButton />
                    </div>
                </div>
                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200">Coin</th>
                                <th className="border-gray-200">Free</th>
                                <th className="border-gray-200">Locked</th>
                                <th className="border-gray-200">Fiat Total</th>
                                <th className="border-gray-200">Avg Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading && balances && balances.length ? (
                                balances
                                    .filter(
                                        b =>
                                            parseFloat(b.available) > 0 ||
                                            parseFloat(b.onOrder) > 0,
                                    )
                                    .map(item => (
                                        <WalletFullRow
                                            key={item.symbol}
                                            data={item}
                                        />
                                    ))
                            ) : (
                                <tr>
                                    <td colSpan={5}>Loading...</td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{total}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
            <NewOrderModal onSubmit={onOrderSubmit} />
        </React.Fragment>
    );
}

export default WalletFull;

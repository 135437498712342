import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

/**
 * props:
 * - data
 * - onDeleteClick
 */
function OrderTemplateRow(props) {
    return (
        <tr>
            <td>{props.data.symbol}</td>
            <td>{props.data.name}</td>
            <td>
                <span className="fw-normal">{props.data.side}</span>
            </td>
            <td>
                <span className="fw-normal">{props.data.type}</span>
            </td>
            <td>
                <a
                    className="btn btn-secondary btn-xs ms-2"
                    title="Edit this Template"
                    href={'/orderTemplate/' + props.data.id}
                >
                    <FontAwesomeIcon
                        icon={faEdit}
                        className="icon icon-xs fa-2x mx-2"
                    />
                </a>
                <button
                    id={'delete' + props.data.id}
                    type="button"
                    className="btn btn-danger btn-xs ms-2"
                    title="Delete this Template"
                    onClick={props.onDeleteClick}
                >
                    <FontAwesomeIcon
                        icon={faTrash}
                        className="icon icon-xs fa-2x mx-2"
                    />
                </button>
            </td>
        </tr>
    );
}

export default OrderTemplateRow;

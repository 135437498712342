import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

/**
 * props:
 * - placeholder
 * - onChange
 */
function SearchSymbol(props) {
    function getPlaceholder() {
        return props.placeholder ? props.placeholder : 'Search symbol';
    }

    return (
        <form className="navbar-search form-inline" id="navbar-search-main">
            <div className="input-group input-group-merge search-bar">
                <span className="input-group-text" id="topbar-addon">
                    <FontAwesomeIcon
                        icon={faSearch}
                        className="icon icon-xs my-vb-icon"
                    />
                </span>
                <input
                    type="text"
                    className="form-control"
                    id="search"
                    placeholder={getPlaceholder()}
                    onChange={props.onChange}
                />
            </div>
        </form>
    );
}

export default SearchSymbol;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit,
    faStop,
    faPlay,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';

/**
 * props:
 * - data
 * - onEditClick
 * - onStopClick
 * - onStartClick
 * - onLogsClick
 */
function MonitorRow(props) {
    function getActiveClass(isActive) {
        return isActive ? 'text-success' : 'text-danger';
    }

    function getActiveText(isActive) {
        return isActive ? 'RUNNING' : 'STOPPED';
    }

    return (
        <tr>
            <td>{props.data.type}</td>
            <td>
                {props.data.symbol ? props.data.symbol : '*'}
                {props.data.interval ? `_${props.data.interval}` : ''}
            </td>
            <td>
                <span className={getActiveClass(props.data.isActive)}>
                    {getActiveText(props.data.isActive)}
                </span>
            </td>
            <td>
                {!props.data.isSystemMon ? (
                    <button
                        id={'edit' + props.data.id}
                        type="button"
                        className="btn btn-secondary btn-xs ms-2"
                        title="Edit this Monitor"
                        data-bs-toggle="modal"
                        data-bs-target="#modalMonitor"
                        onClick={props.onEditClick}
                    >
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="icon icon-xs fa-lg mx-1"
                        />
                    </button>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {props.data.isActive && !props.data.isSystemMon ? (
                    <button
                        id={'stop' + props.data.id}
                        type="button"
                        className="btn btn-danger btn-xs ms-2"
                        title="Stop this Monitor"
                        onClick={props.onStopClick}
                    >
                        <FontAwesomeIcon
                            icon={faStop}
                            className="icon icon-xs fa-lg mx-1"
                        />
                    </button>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {!props.data.isActive && !props.data.isSystemMon ? (
                    <button
                        id={'start' + props.data.id}
                        type="button"
                        className="btn btn-success btn-xs ms-2"
                        title="Start this Monitor"
                        onClick={props.onStartClick}
                    >
                        <FontAwesomeIcon
                            icon={faPlay}
                            className="icon icon-xs fa-lg mx-1"
                        />
                    </button>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {!props.data.isActive && !props.data.isSystemMon ? (
                    <button
                        id={'delete' + props.data.id}
                        type="button"
                        className="btn btn-danger btn-xs ms-2"
                        title="Delete this Monitor"
                        onClick={props.onDeleteClick}
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="icon icon-xs fa-lg mx-1"
                        />
                    </button>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {props.data.isSystemMon ? (
                    <button
                        id={'logs' + props.data.id}
                        type="button"
                        className="btn btn-info btn-xs ms-2"
                        title="Logs from this Monitor"
                        data-bs-toggle="modal"
                        data-bs-target="#modalLogs"
                        onClick={props.onLogsClick}
                    >
                        <svg
                            className="icon icon-xs"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z" />
                            <path
                                fillRule="evenodd"
                                d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
            </td>
        </tr>
    );
}

export default MonitorRow;

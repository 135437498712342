import React from 'react';
import SmartBadge from '../../../../components/SmartBadge/SmartBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faSms,
    faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';

/**
 * props:
 * - action
 * - onClick
 */
function ActionBadge(props) {
    function renderBadge(actionType) {
        let image,
            text = '';
        if (actionType === 'ALERT_EMAIL') {
            image = (
                <FontAwesomeIcon
                    icon={faEnvelope}
                    className="icon icon-xs fa-2x"
                />
            );
        } else if (actionType === 'ALERT_SMS')
            image = (
                <FontAwesomeIcon icon={faSms} className="icon icon-xs fa-2x" />
            );
        else if (actionType === 'ALERT_TELEGRAM')
            image = (
                <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                        clipRule="evenodd"
                    />
                </svg>
            );
        else if (['ORDER', 'TRAILING'].includes(actionType)) {
            text =
                props.action.orderTemplateName ||
                props.action.orderTemplate.name;
            image = (
                <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    className="icon icon-xs fa-2x mx-2"
                />
            );
        } else if (actionType === 'WITHDRAW') {
            text =
                props.action.withdrawTemplateName ||
                props.action.withdrawTemplate.name;
            image = (
                <svg
                    className="icon icon-xs me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                    <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                        clipRule="evenodd"
                    />
                </svg>
            );
        }

        return (
            <SmartBadge
                id={props.action.id}
                text={text}
                onClick={props.onClick}
            >
                {image}
            </SmartBadge>
        );
    }

    return renderBadge(props.action.type);
}

export default ActionBadge;

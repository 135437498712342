import React from 'react';

/**
 * props:
 * - onClick
 */
function StopAllAutomationsButton(props) {
    return (
        <React.Fragment>
            <button
                type="button"
                className="btn btn-danger mx-3"
                onClick={props.onClick}
            >
                <svg
                    className="icon icon-xs me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={props.onClick}
                >
                    <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z"
                        clipRule="evenodd"
                    />
                </svg>
                Stop All Automations
            </button>
        </React.Fragment>
    );
}

export default StopAllAutomationsButton;

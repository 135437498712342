import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTh, faHandPaper } from '@fortawesome/free-solid-svg-icons';

/**
 * props:
 * - data
 * - onClick
 */
function OrderRow(props) {
    function getDate(timestamp) {
        const date = new Date(Number(timestamp));
        const frm = new Intl.DateTimeFormat('en-US', {
            dateStyle: 'short',
            timeStyle: 'short',
        }).format(date);
        return frm;
    }

    function getStatus(status) {
        let className;
        switch (status) {
            case 'PARTIALLY_FILLED':
                className = 'badge bg-info py-1';
                break;
            case 'FILLED':
                className = 'badge bg-success py-1';
                break;
            case 'REJECTED':
            case 'EXPIRED':
            case 'CANCELED':
                className = 'badge bg-danger py-1';
                break;
            default:
                className = 'badge bg-warning py-1';
                break;
        }
        return <span className={className}>{status.split('_')[0]}</span>;
    }

    return (
        <tr>
            <td>
                {props.data.automationId ? (
                    <FontAwesomeIcon
                        icon={faTh}
                        className="icon icon-xs me-2 fa-2x"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faHandPaper}
                        className="icon icon-xs me-2 fa-2x"
                    />
                )}
                {props.data.side === 'BUY' ? (
                    <span className="badge bg-warning py-1 me-2">Buy</span>
                ) : (
                    <span className="badge bg-warning py-1 me-2">Sell</span>
                )}
                {props.data.symbol}
            </td>
            <td>
                <span className="fw-normal">
                    {getDate(props.data.transactTime)}
                </span>
            </td>
            <td>
                <span className="fw-normal">{props.data.quantity}</span>
            </td>
            <td>
                <span className="fw-bold">{props.data.net}</span>
            </td>
            <td>{getStatus(props.data.status)}</td>
            <td>
                <button
                    id={'view' + props.data.id}
                    type="button"
                    className="btn btn-info btn-xs"
                    data-bs-toggle="modal"
                    data-bs-target="#modalViewOrder"
                    onClick={props.onClick}
                >
                    <FontAwesomeIcon
                        icon={faEye}
                        className="icon icon-xs my-vb-icon"
                    />
                </button>
            </td>
        </tr>
    );
}

export default OrderRow;

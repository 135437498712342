import React, { useEffect, useState, useRef } from 'react';
import '../../Automations.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

/**
 * props:
 * - selectedIndex
 * - indexes
 * - onAddClick
 */
function VariableInput(props) {
    const variableRef = useRef('');
    const [index, setIndex] = useState({});
    const [operator, setOperator] = useState('==');
    const [variable, setVariable] = useState('');
    const [indexes, setIndexes] = useState({});

    useEffect(() => {
        setIndex(props.selectedIndex);
        setVariable(props.selectedIndex.example);
        variableRef.current.value = '';
    }, [props.selectedIndex]);

    useEffect(() => {
        setIndexes(props.indexes);
    }, [props.indexes]);

    function getOptionText(symbol, variable) {
        return variable.startsWith('WALLET_')
            ? `${symbol}:${variable}`
            : variable;
    }

    function getExpressionText() {
        const value =
            typeof index.example === 'string' ? `'${variable}'` : variable;
        return `${index.symbol}:${index.variable} ${operator.replace(
            '==',
            '=',
        )} ${value}`;
    }

    function onAddClick(event) {
        const value =
            typeof index.example === 'string' ? `'${variable}'` : variable;
        const condition = {
            eval: `${index.eval}${operator}${value}`,
            text: getExpressionText(),
        };
        props.onAddClick({ target: { id: 'condition', value: condition } });

        setOperator('==');
        variableRef.current.value = '';
    }

    function onOperatorChange(event) {
        setOperator(event.target.value);
    }

    function onVariableChange(event) {
        const value = event.target.value;
        const index = props.indexes.find(k => value.endsWith(k.variable));
        if (index && value.indexOf('WALLET_') === -1) setVariable(index.eval);
        else setVariable(value);
    }

    return (
        <div className="input-group input-group-merge mb-2">
            <span className="input-group-text bg-secondary">is</span>
            <select
                id="operator"
                className="form-select"
                onChange={onOperatorChange}
                value={operator}
            >
                {typeof index.example === 'number' ? (
                    <React.Fragment>
                        <option value=">">greater than</option>
                        <option value=">=">greater or equals</option>
                        <option value="<">less than</option>
                        <option value="<=">less or equals</option>
                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                <option value="==">equals</option>
                <option value="!=">not equals</option>
            </select>
            <input
                ref={variableRef}
                id="variable"
                list="variables"
                type="text"
                className="form-control"
                onChange={onVariableChange}
                placeholder={`${index.example}`}
            />
            <datalist id="variables">
                {indexes && Array.isArray(indexes) ? (
                    indexes
                        .filter(i => i.eval !== index.eval)
                        .map(item => (
                            <option key={`${item.symbol}:${item.variable}`}>
                                {getOptionText(item.symbol, item.variable)}
                            </option>
                        ))
                ) : (
                    <option value="">NO INDEXES</option>
                )}
            </datalist>
            <button
                type="button"
                className="btn btn-secondary"
                onClick={onAddClick}
            >
                <FontAwesomeIcon icon={faPlus} className="icon icon-xs" />
            </button>
        </div>
    );
}

export default VariableInput;

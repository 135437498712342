import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { doLogin } from '../../services/AuthService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faEnvelope,
    faLock,
} from '@fortawesome/free-solid-svg-icons';

function Login() {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    function onChangeInput(event) {
        if (event.target.id === 'email') setEmail(event.target.value);
        else setPassword(event.target.value);
    }

    function onSubmit(event) {
        event.preventDefault();

        doLogin(email, password)
            .then(response => {
                localStorage.setItem('token', response.token);
                localStorage.setItem('hasFutures', response.hasFutures);
                localStorage.setItem('id', response.id);
                history.push('/dashboard');
            })
            .catch(err => {
                console.error(err);
                setError(`Invalid user and/or password!`);
            });
    }

    return (
        <main>
            <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
                <div className="container">
                    <p className="text-center">
                        <Link
                            to="/"
                            className="d-flex align-items-center justify-content-center"
                        >
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                className="icon icon-xs me-2 my-vb-icon"
                            />
                            Back to homepage
                        </Link>
                    </p>
                    <div className="row justify-content-center form-bg-image">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center">
                                    <img
                                        src="./img/favicon/mstile-150x150.png"
                                        alt="Argos TraderBot Logo"
                                        width={64}
                                    />
                                </div>
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h1 className="mb-0 h3">
                                        Sign in to our platform
                                    </h1>
                                </div>
                                <form
                                    action="#"
                                    className="mt-4"
                                    onSubmit={onSubmit}
                                >
                                    <div className="form-group mb-4">
                                        <label htmlFor="email">
                                            Your Email
                                        </label>
                                        <div className="input-group">
                                            <span
                                                className="input-group-text"
                                                id="basic-addon1"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEnvelope}
                                                    className="icon icon-xs text-gray-600 my-vb-icon"
                                                />
                                            </span>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="example@company.com"
                                                id="email"
                                                autoFocus
                                                required
                                                onChange={onChangeInput}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group mb-4">
                                            <label htmlFor="password">
                                                Your Password
                                            </label>
                                            <div className="input-group">
                                                <span
                                                    className="input-group-text"
                                                    id="basic-addon2"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faLock}
                                                        className="icon icon-xs text-gray-600 my-vb-icon"
                                                    />
                                                </span>
                                                <input
                                                    type="password"
                                                    placeholder="Password"
                                                    className="form-control"
                                                    id="password"
                                                    required
                                                    onChange={onChangeInput}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-top mb-4">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="remember"
                                                />
                                                <label
                                                    className="form-check-label mb-0"
                                                    htmlFor="remember"
                                                >
                                                    Remember me
                                                </label>
                                            </div>
                                            <div>
                                                <Link
                                                    to="/forgot-password"
                                                    className="small text-right"
                                                >
                                                    Lost password?
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <button
                                            type="submit"
                                            className="btn btn-gray-800"
                                        >
                                            Sign in
                                        </button>
                                    </div>
                                    {error ? (
                                        <div className="alert alert-danger mt-2">
                                            {error}
                                        </div>
                                    ) : (
                                        <React.Fragment></React.Fragment>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Login;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';

function NewOrderTemplateButton() {
    return (
        <a className="btn btn-primary animate-up-2" href="/orderTemplate/">
            <FontAwesomeIcon
                icon={faFileSignature}
                className="icon icon-xs fa-2x"
            />
            New Order Template
        </a>
    );
}

export default NewOrderTemplateButton;

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { doLogout } from '../../services/AuthService';
import SideBarItem from './SideBarItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTachometerAlt,
    faFileInvoiceDollar,
    faTh,
    faCog,
    faSignOutAlt,
    faTimes,
    faDesktop,
    faFileSignature,
} from '@fortawesome/free-solid-svg-icons';

function SideBar() {
    const history = useHistory();

    function cleanAndRedirect() {
        localStorage.removeItem('token');
        history.push('/');
    }

    function onLogoutClick(event) {
        doLogout()
            .then(response => cleanAndRedirect())
            .catch(error => {
                console.error(error);
                cleanAndRedirect();
            });
    }

    return (
        <nav
            id="sidebarMenu"
            className="sidebar d-lg-block bg-gray-800 text-white collapse"
            datasimplebar="true"
        >
            <div className="sidebar-inner px-4 pt-3">
                <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                    <div className="collapse-close d-md-none">
                        <a
                            href="#sidebarMenu"
                            data-bs-toggle="collapse"
                            data-bs-target="#sidebarMenu"
                            aria-controls="sidebarMenu"
                            aria-expanded="true"
                            aria-label="Toggle navigation"
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="icon icon-xs my-vb-icon"
                            />
                        </a>
                    </div>
                </div>
                <ul className="nav flex-column pt-3 pt-md-0">
                    <li className="nav-item">
                        <Link
                            to="/dashboard"
                            className="nav-link d-flex align-items-center"
                        >
                            <span className="sidebar-icon">
                                <img
                                    src="/img/favicon/favicon-32x32.png"
                                    height="32"
                                    width="32"
                                    alt="Argos TraderBot Logo"
                                />
                            </span>
                            <span className="mt-1 ms-1 sidebar-text">
                                Argos TraderBot
                            </span>
                        </Link>
                    </li>
                    <li
                        role="separator"
                        className="dropdown-divider mt-4 mb-3 border-gray-700"
                    ></li>
                    <li className="nav-item">
                        <span
                            className="nav-link collapsed d-flex justify-content-between align-items-center"
                            data-bs-toggle="collapse"
                            data-bs-target="#submenu-intelligence"
                        >
                            <span>
                                <span className="sidebar-icon">
                                    <svg
                                        className="icon icon-xs me-2"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                                        <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                                    </svg>
                                </span>
                                <span className="sidebar-text">
                                    Intelligence
                                </span>
                            </span>
                            <span className="link-arrow">
                                <svg
                                    className="icon icon-sm"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </span>
                        </span>
                        <div
                            className="multi-level collapse"
                            role="list"
                            id="submenu-intelligence"
                        >
                            <ul className="flex-column nav">
                                <SideBarItem to="/dashboard" text="Dashboard" />
                                <SideBarItem to="/reports" text="Reports" />
                                <SideBarItem to="/wallet" text="Wallet" />
                            </ul>
                        </div>
                    </li>
                    <SideBarItem
                        to="/orders"
                        text={
                            localStorage.getItem('hasFutures') === 'true'
                                ? 'Orders & Positions'
                                : 'Orders'
                        }
                    >
                        <FontAwesomeIcon
                            icon={faFileInvoiceDollar}
                            className="icon icon-xs me-2 my-vb-icon"
                        />
                    </SideBarItem>
                    <SideBarItem to="/strategies" text="Strategies">
                        <svg
                            className="icon icon-xs me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
                        </svg>
                    </SideBarItem>
                    <SideBarItem to="/automations" text="Automations">
                        <FontAwesomeIcon
                            icon={faTh}
                            className="icon icon-xs me-2 my-vb-icon"
                        />
                    </SideBarItem>
                    <li className="nav-item">
                        <span
                            className="nav-link collapsed d-flex justify-content-between align-items-center"
                            data-bs-toggle="collapse"
                            data-bs-target="#submenu-templates"
                        >
                            <span>
                                <span className="sidebar-icon">
                                    <svg
                                        className="icon icon-xs me-2"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z" />
                                    </svg>
                                </span>
                                <span className="sidebar-text">
                                    Action Templates
                                </span>
                            </span>
                            <span className="link-arrow">
                                <svg
                                    className="icon icon-sm"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </span>
                        </span>
                        <div
                            className="multi-level collapse"
                            role="list"
                            id="submenu-templates"
                        >
                            <ul className="flex-column nav">
                                <SideBarItem
                                    to="/orderTemplates"
                                    text="Order"
                                />
                                <SideBarItem to="/webhooks" text="WebHook" />
                                <SideBarItem
                                    to="/withdrawTemplates"
                                    text="Withdraw"
                                />
                            </ul>
                        </div>
                    </li>
                    <SideBarItem to="/monitors" text="Monitors">
                        <FontAwesomeIcon
                            icon={faDesktop}
                            className="icon icon-xs me-2 my-vb-icon"
                        />
                    </SideBarItem>
                    <li className="nav-item">
                        <span
                            className="nav-link collapsed d-flex justify-content-between align-items-center"
                            data-bs-toggle="collapse"
                            data-bs-target="#submenu-settings"
                        >
                            <span>
                                <span className="sidebar-icon">
                                    <svg
                                        className="icon icon-xs me-2"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </span>
                                <span className="sidebar-text">Settings</span>
                            </span>
                            <span className="link-arrow">
                                <svg
                                    className="icon icon-sm"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </span>
                        </span>
                        <div
                            className="multi-level collapse"
                            role="list"
                            id="submenu-settings"
                        >
                            <ul className="flex-column nav">
                                <SideBarItem to="/settings" text="Personal" />
                                <SideBarItem to="/symbols" text="Symbols" />
                            </ul>
                        </div>
                    </li>
                    <li
                        role="separator"
                        className="dropdown-divider mt-4 mb-3 border-gray-700"
                    ></li>
                    <SideBarItem to="/" text="Logout" onClick={onLogoutClick}>
                        <FontAwesomeIcon
                            icon={faSignOutAlt}
                            className="icon icon-xs me-2 my-vb-icon"
                        />
                    </SideBarItem>
                </ul>
            </div>
        </nav>
    );
}

export default SideBar;
